// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-directions-js": () => import("/opt/build/repo/src/pages/Directions.js" /* webpackChunkName: "component---src-pages-directions-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-js": () => import("/opt/build/repo/src/pages/Registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rsvp-js": () => import("/opt/build/repo/src/pages/RSVP.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-story-js": () => import("/opt/build/repo/src/pages/Story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

